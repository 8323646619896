import Encoding from 'Encoding';
import { View } from 'backbone';
// import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import CitiesView from '@/js/app/address/views/cities';
import PrefecturesView from '@/js/app/address/views/prefectures';
import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ChangelogsView from '@/js/app/changelog/views/changelogs';
import config from '@/js/app/config';
import * as Formatter from '@/js/app/formatter';
import Texts from '@/js/app/generic/texts';
import OAuth2Client from '@/js/app/oauth2-client';
import Session from '@/js/app/session';
import template from '../templates/edit_location.html';
import PropertyLocationStationsView from './location-stations';

export default class PropertyLocationView extends View {
    #mode = 'read';

    preinitialize() {
        // Google Maps geocoder
        this.geocoder = new window.google.maps.Geocoder();

        // Google Maps map
        this.map = null;

        // Google Maps marker
        this.marker = null;

        this.events = {
            'click [data-action="lookup"]': this.handleClickLookupAddress,
            'change [data-group="address"]': this.handleChangeAddressField,
            'change [name="prefecture"]': this.handleChangePrefecture,
            'set [name="prefecture"]': this.handleSetPrefecture,
            'click [name="address_map_pin_connected"]': this.handleChangeUpdateAddressMapPinConnected,
            'click legend.trainlines [data-action="lookup-stations"]': this.handleClickLookupStations,
            'change input[name="approved_by"]': this.handleChangeApprovedBy,
            'click button[data-action="changelog"]': this.handleChangelogClick,
            'click button[data-action="create"]': this.create,
            'click button[data-action="edit"]': this.handleEditClick,
            'click button[data-action="cancel"]': this.handleCancelClick,
            'click button[data-action="save"]': this.handleSaveClick,
        };
    }

    initialize() {
        _.bindAll(this, 'render', 'create', 'updateGeocode');

        this.subviews = {
            prefecture: new PrefecturesView({
                attributes: {
                    name: 'prefecture',
                    'data-group': 'address'
                }
            }),
            city: new CitiesView({
                attributes: {
                    name: 'city',
                    'data-group': 'address'
                }
            }),
            stations: new PropertyLocationStationsView({
                model: this.model,
            }),
        };

        // When geocode changes: lookup address, lookup stations, center map
        this.listenTo(this.model, 'changeGeocode', this.lookupAddressFromGeocode);
        this.listenTo(this.model, 'changeGeocode', this.lookupStationFromGeocode);
        this.listenTo(this.model, 'changeGeocode', this.centerMap);

        // When model changes, update outlets
        this.listenTo(this.model, 'change', this.updateOutlets);

        // When address changes: lookup geocode
        this.listenTo(this.model, 'changeAddress', this.lookupGeocodeFromAddress);
    }

    render() {
        console.debug('PropertyLocationView#render');

        this.el.innerHTML = template({
            locationId: this.model.id,
            staffId: Session.data.staff_id,
            is_approved: this.isAddressApproved(),
            last_updated_at: Formatter.timestamp(this.model.get('updated_at')),
            approvedAt: Formatter.timestamp(this.model.get('approved_at')),
            areCoordinatesComplete: (this.model.get('latitude') && this.model.get('longitude')) ? true : false,
        });

        // Render prefecture subview and start fetching prefectures
        this.subviews.prefecture.render(this.el.querySelector('[data-slot="prefecture"]'));
        this.subviews.prefecture.collection.fetch();

        // Render city subview
        this.subviews.city.render(this.el.querySelector('[data-slot="city"]'));

        // Set element for stations subview
        this.subviews.stations.setElement(this.el.querySelector('[data-slot="stations"]'));

        // Initialize map
        const myOptions = {
            zoom: 18,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        };

        // If any part of geocode is null, default map to Tokyo, otherwise use geocode
        const latLng = (this.model.get('latitude') === null || this.model.get('longitude') === null
            ? new window.google.maps.LatLng(35.6894875, 139.69170639999993)    // Tokyo geocode
            : new window.google.maps.LatLng(this.model.get('latitude'), this.model.get('longitude'))
        );

        this.map = new window.google.maps.Map(this.el.querySelector('#map_canvas'), myOptions);
        this.marker = new window.google.maps.Marker({
            map: this.map,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            position: latLng,
        });

        // Set map center and marker position
        this.map.setCenter(latLng);
        this.marker.setPosition(latLng);

        // Setup marker for dragging
        window.google.maps.event.clearListeners(this.marker, 'dragend');
        window.google.maps.event.addListener(this.marker, 'dragend', this.updateGeocode);

        this.updateOutlets();

        this.#initializeReadEditMode();
    }

    switchReadMode() {
        console.debug('PropertyLocationView#switchReadMode');

        this.#mode = 'read';

        this.#initializeReadEditMode();

        // Remove was-validated class from form
        const form = this.el.querySelector('form');
        form.classList.remove('was-validated');

        this.#disablePromptBeforeUnload();
    }

    switchEditMode() {
        console.debug('PropertyLocationView#switchEditMode');

        // Snapshot model
        this.model.snapshot();

        this.#mode = 'edit';

        this.#initializeReadEditMode();

        this.#enablePromptBeforeUnload();
    }

    promptBeforeUnload(e) {
        // Prevent default (modern browsers)
        e.preventDefault();

        // Set return value (legacy browsers)
        e.returnValue = true;
    }

    #initializeReadEditMode() {
        console.debug('PropertyLocationView##initializeReadEditMode');

        if (this.#mode === 'read') {
            // Disable fields in view
            this.el.querySelectorAll('input,select,textarea').forEach(el => el.disabled = true);

            // Show edit button
            const editButton = this.el.querySelector('[data-action="edit"]');
            if (editButton) {
                editButton.classList.remove('d-none');
            }

            // Hide cancel and save buttons
            this.el.querySelectorAll('[data-action="cancel"],[data-action="save"]').forEach(el => el.classList.add('d-none'));

            // Disable marker
            if ('undefined' !== typeof this.marker) {
                this.marker.setDraggable(false);
            }

            // Disable postcode button
            this.$el.find('[data-action="lookup"]').prop('disabled', true);

            // Disable train station buttons
            this.$el.find('ol.train_lines li a, legend.trainlines a').hide();
        } else if (this.#mode === 'edit') {
            // Enable fields in view
            this.el.querySelectorAll('input,select,textarea').forEach(el => el.disabled = false);

            // Hide edit button
            const editButton = this.el.querySelector('[data-action="edit"]');
            if (editButton) {
                editButton.classList.add('d-none');
            }

            // Hide cancel and save buttons
            this.el.querySelectorAll('[data-action="cancel"],[data-action="save"]').forEach(el => el.classList.remove('d-none'));

            // Enable marker
            if ('undefined' !== typeof this.marker) {
                this.marker.setDraggable(true);
            }

            // Enable postcode button
            this.$el.find('[data-action="lookup"]').prop('disabled', false);

            // Enable train station buttons
            this.$el.find('ol.train_lines li a, legend.trainlines a').show();
        }
    }

    #disablePromptBeforeUnload() {
        // Remove prompt before unload
        window.removeEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload--;
    }

    #enablePromptBeforeUnload() {
        // Add prompt before unload
        window.addEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload++;
    }

    resetValidation($e) {
        console.debug('PropertyLocationView#resetValidation');

        if ($e instanceof Object) {
            const fieldEl = $e.target;

            // Reset invalid feedback slot
            const errorFeedback = fieldEl.parentElement.querySelector('.invalid-feedback');
            if (errorFeedback) {
                errorFeedback.innerText = '';
            }

            // Reset custom validity
            fieldEl.setCustomValidity('');
        }
    }

    save() {
        console.debug('PropertyLocationView#save');

        const form = this.el.querySelector('form');

        // Add was-validated class to form
        form.classList.add('was-validated');

        // this.hideValidationMessage();

        if (form.checkValidity() === false) {
            return;
        }

        let payload;
        if (this.model.isNew()) {
            // Use entire model contents
            payload = this.model.toJSON();
        } else {
            // Get model changes since last snapshot
            payload = this.model.changedAttributesSinceSnapshot();
        }

        // If no changes; immediately switch to read mode
        if (_.isEmpty(payload)) {
            this.switchReadMode();
        }
        else {
            // Save new value
            this.model.save(payload, { patch: true, wait: true })
                .then(() => {
                    this.switchReadMode();
                })
                .catch(({ responseJSON }) => {
                    // If type of error is App\Exceptions\Validation
                    if (responseJSON.type === 'App\\Exceptions\\Validation') {
                        for (const field of responseJSON.fields) {
                            const fieldEl = this.el.querySelector(`[name="${field.name}"]`);

                            // Set validation error in invalid feedback slot
                            const errorFeedback = fieldEl.parentElement.querySelector('.invalid-feedback');
                            if (errorFeedback) {
                                errorFeedback.innerText = field.message;
                            }

                            // Set validation error on fields
                            fieldEl.setCustomValidity(field.message);
                        }

                        // this.showValidationMessage();
                    }
                });
        }
    }

    handleClickLookupAddress() {
        console.debug('PropertyLocationView#handleClickLookupAddress');

        // If postcode blank, return
        const fieldPostcode = this.el.querySelector('[name="postcode"]');
        if (fieldPostcode.value === '') {
            return;
        }

        this.lookupAddressFromPostcode(fieldPostcode.value);
    }

    async lookupAddressFromPostcode(postcode) {
        console.debug('PropertyLocationView#lookupAddressFromPostcode');

        const address = await OAuth2Client.fetchJSON(config.api.url + 'postcodes/' + postcode);

        // Set address components in form
        this.changeAddress({
            postcode: address.postcode,
            prefecture: address.prefecture,
            city: address.city,
            neighborhood: address.neighborhood,
        });
    }

    changeAddress(changes) {
        console.debug('PropertyLocationView#changeAddress');

        this.model.set(changes);

        // Flag geocode as not in sync
        this.model.geocodeInSync = false;

        this.model.trigger('changeAddress');
    }

    handleChangePrefecture(e) {
        console.debug('PropertyLocationView#handleChangePrefecture');

        this.fetchCities(e.currentTarget.value);
    }

    handleSetPrefecture(e, params) {
        console.debug('PropertyLocationView#handleSetPrefecture');

        this.fetchCities(params.value);
    }

    handleChangeUpdateAddressMapPinConnected(e) {
        console.debug('PropertyLocationView#handleChangeUpdateAddressMapPinConnected');

        // Set use_geocode on model to inverse of checked property
        this.model.set({
            use_geocode: e.currentTarget.checked ? 0 : 1,
        });
    }

    handleChangeAddressField(e) {
        console.debug('PropertyLocationView#handleChangeAddressField');

        const t = this.$el.find(e.currentTarget);

        this.changeAddress({
            [t.prop('name')]: t.val()
        });
    }

    fetchCities(prefecture) {
        console.debug('PropertyLocationView#fetchCities');

        if (prefecture) {
            // Initiate fetch with form data
            this.subviews.city.collection.fetch({
                data: { prefecture }
            });
        } else {
            // Clear collection
            this.subviews.city.collection.reset();
        }
    }

    updateOutlets() {
        console.debug('PropertyLocationView#updateOutlets');

        const changes = this.model.changedAttributes();

        console.debug('PropertyLocationView#updateOutlets: Changes', changes);

        populateForm(this.el, _.omit(changes, ['prefecture', 'city', 'use_geocode', 'stations']));

        if (_.has(changes, 'prefecture')) {
            // Set prefecture
            this.subviews.prefecture.setValue(this.model.get('prefecture'));
        }
        if (_.has(changes, 'city')) {
            // Set city
            this.subviews.city.setValue(this.model.get('city'));
        }

        // Set "address_map_pin_connected" to the inverse of use_geocode
        this.$el.find('[name="address_map_pin_connected"]').prop('checked', !this.model.get('use_geocode'));

        if (_.has(changes, 'stations')) {
            // Render stations
            this.subviews.stations.render();
        }
    }

    handleChangeApprovedBy(e) {
        console.debug('PropertyLocationView#handleChangeApprovedBy');

        e.preventDefault();

        const approvedByEl = this.$el.find('span[data-field="approved_by"]');

        if (e.currentTarget.checked) {
            approvedByEl.html('Approved just <i>moments ago</i>.');
        } else {
            approvedByEl.html('Mark this location as checked and approved.');
        }
    }

    create(e) {
        console.debug('PropertyLocationView#create');

        e.preventDefault();

        // Disable button to prevent duplicate submissions
        this.$el.find(e.currentTarget).prop('disabled', true);

        this.#disablePromptBeforeUnload();

        // Persist model
        this.model.persist();

        sessionStorage.removeItem('new-property-data');
    }

    handleClickLookupStations(e) {
        console.debug('PropertyLocationView#handleClickStationLookup');

        e.preventDefault();

        this.lookupStationFromGeocode();
    }

    lookupStationFromGeocode() {
        console.debug('PropertyLocationView#lookupStationFromGeocode');

        const query = {
            lat: this.model.get('latitude'),
            lon: this.model.get('longitude'),
            minutes_away: 30,
            language: 'ja',
            limit: 15,
        };

        const requestUrl = new URL(config.api.url + 'nearest_train_stations');
        requestUrl.search = jQuery.param(query);

        OAuth2Client.fetchJSON(requestUrl, {})
            .then((results) => {
                this.model.set('stations', results);
            });
    }

    centerMap() {
        console.debug('PropertyLocationView#centerMap');

        // Update map position and marker
        const latLng = new window.google.maps.LatLng(
            this.model.get('latitude'),
            this.model.get('longitude')
        );
        this.map.setCenter(latLng);
        this.marker.setPosition(latLng);
    }

    lookupGeocodeFromAddress() {
        console.debug('PropertyLocationView#lookupGeocodeFromAddress');

        // If map pin and address not connected; return
        if (this.el.querySelector('#field-address_map_pin_connected').checked === false) {
            console.debug('PropertyLocationView#lookupGeocodeFromAddress: Map pin and address not connected');
            return;
        }

        if (this.model.geocodeInSync) {
            console.debug('PropertyLocationView#lookupGeocodeFromAddress: Geocode already in sync');
            return;
        }

        // Create request using address
        const request = {
            region: 'jp',
            language: 'ja',
            address: this.model.get('prefecture') + this.model.get('city') + this.model.get('neighborhood') + this.model.get('address'),
        };

        this.geocoder.geocode(request, (results, status) => {
            if ('OK' !== status) {
                this.#handleGeocoderError();
            } else {
                /*
                if ('undefined' !== typeof results[0].partial_match && results[0].partial_match) {
                    // Display error message
                    this.$el.find('div.location fieldset:first div.alert').prop('class', 'alert alert-warning').text(Texts.INCOMPLETE_LOCATION).show();

                    // Disable primary button
                    this.$el.find('div.location button.btn-primary').prop('disabled', true);
                }
                else {
                    // Hide error message
                    this.$el.find('div.location fieldset:first div.alert').hide();

                    // Enable primary button
                    this.$el.find('div.location button.btn-primary').prop('disabled', false);
                }
                */

                // Update parts of form
                this.model.set({
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng(),
                });

                this.model.geocodeInSync = true;

                this.model.trigger('changeGeocode');
            }
        });
    }

    lookupAddressFromGeocode() {
        console.debug('PropertyLocationView#lookupAddressFromGeocode');

        // If map pin and address not connected; return
        if (this.el.querySelector('#field-address_map_pin_connected').checked === false) {
            console.debug('PropertyLocationView#lookupAddressFromGeocode: Map pin and address not connected');
            return;
        }

        if (this.model.addressInSync) {
            console.debug('PropertyLocationView#lookupAddressFromGeocode: Address already in sync');
            return;
        }

        // Create request using geocode
        const request = {
            region: 'jp',
            language: 'ja',
            latLng: {
                lat: this.model.get('latitude'),
                lng: this.model.get('longitude'),
            },
        };

        this.geocoder.geocode(request, (results, status) => {
            if ('OK' !== status) {
                this.#handleGeocoderError();
            } else {
                /*
                if ('undefined' !== typeof results[0].partial_match && results[0].partial_match) {
                    // Display error message
                    this.$el.find('div.location fieldset:first div.alert').prop('class', 'alert alert-warning').text(Texts.INCOMPLETE_LOCATION).show();

                    // Disable primary button
                    this.$el.find('div.location button.btn-primary').prop('disabled', true);
                }
                else {
                    // Hide error message
                    this.$el.find('div.location fieldset:first div.alert').hide();

                    // Enable primary button
                    this.$el.find('div.location button.btn-primary').prop('disabled', false);
                }
                */

                // Map address components from all results to address data
                const addrData = {};
                results[0].address_components.forEach((a) => {
                    if (a.types.includes('postal_code')) {
                        addrData.post_code = a.long_name.replace('-', '');
                    }
                    else if (a.types.includes('administrative_area_level_1')) {
                        addrData.administrative_area_level_1 = a.long_name;
                    }
                    else if (a.types.includes('locality')) {
                        addrData.locality = a.long_name;
                    }
                    else if (a.types.includes('ward')) {
                        addrData.ward = a.long_name;
                    }
                    else if (a.types.includes('sublocality_level_1')) {
                        addrData.sublocality_level_1 = a.long_name;
                    }
                    else if (a.types.includes('sublocality_level_2')) {
                        addrData.sublocality_level_2 = a.long_name;
                    }
                    else if (a.types.includes('sublocality_level_3')) {
                        addrData.sublocality_level_3 = a.long_name;
                    }
                    else if (a.types.includes('sublocality_level_4')) {
                        addrData.sublocality_level_4 = a.long_name;
                    }
                    else if (a.types.includes('premise')) {
                        addrData.premise = a.long_name;
                    }
                });

                const processedAddress = this.#processGoogleAddress(addrData);

                // Update address in model
                this.model.set({
                    postcode: processedAddress.postal_code,
                    prefecture: processedAddress.prefecture,
                    city: processedAddress.city,
                    neighborhood: processedAddress.neighborhood,
                    address: processedAddress.address,
                });

                this.model.addressInSync = true;

                this.model.trigger('changeAddress');
            }
        });
    }

    #handleGeocoderError() {
        // Display error message
        this.$el.find('div.location fieldset:first div.alert').prop('class', 'alert alert-danger').text(Texts.BAD_GEOCODER_STATUS).show();

        // Disable primary button
        this.$el.find('div.location button.btn-primary').prop('disabled', true);

        // Clear train lines
        $('ol.train_lines').empty();

    }

    updateGeocode() {
        console.debug('PropertyLocationView#updateGeocode');

        // Update latitude and longitude in model
        const latLng = this.marker.getPosition();
        this.model.set({
            latitude: latLng.lat(),
            longitude: latLng.lng(),
        });

        this.model.addressInSync = false;

        this.model.trigger('changeGeocode');
    }

    handleChangelogClick(e) {
        console.debug('PropertyLocationView#handleChangelogClick');

        e.stopPropagation();

        // Create modal view, with collection
        const changelogView = new ChangelogsView({
            collection: new ChangelogsCollection(),
        });

        // Fetch collection
        changelogView.collection.fetch({
            data: {
                resource_type: 'property_location',
                resource_id: this.model.id,
            },
        });
    }

    isAddressApproved() {
        return !!this.model.get('approved_by');
    }

    #processGoogleAddress(googleAddress) {
        console.debug('PropertyLocationView##processGoogleAddress');

        let city = googleAddress.locality;
        if (googleAddress.sublocality_level_1) {
            city += googleAddress.sublocality_level_1;
        }

        let address = [];
        if (googleAddress.sublocality_level_3) {
            address.push(googleAddress.sublocality_level_3.replace('丁目', ''));
        }

        if (googleAddress.sublocality_level_4) {
            address.push(googleAddress.sublocality_level_4);
        }

        if (googleAddress.premise) {
            address.push(googleAddress.premise.replace('番地', '-').replace('−', '-'));
        }

        // Convert address to hankaku
        address = Encoding.toHankakuCase(address.join('-'));

        return {
            postal_code: googleAddress.post_code,
            prefecture: googleAddress.administrative_area_level_1,
            city,
            neighborhood: googleAddress.sublocality_level_2,
            address,
        };
    }

    handleEditClick(e) {
        console.debug('PropertyLocationView#handleEditClick');

        e.stopPropagation();

        this.switchEditMode();
    }

    handleCancelClick(e) {
        console.debug('PropertyLocationView#handleCancelClick');

        e.stopPropagation();

        this.model.restoreFromSnapshot();

        // Need to center map again since geocode changed back
        /**
         * @todo Consider splitting center map into different functions - one to center the map
         * and one to adjust the marker. In some cases we need to call independently.
         */
        this.centerMap();

        this.switchReadMode();

        // this.hideValidationMessage();
    }

    handleSaveClick(e) {
        console.debug('PropertyLocationView#handleSaveClick');

        e.stopPropagation();

        this.save();
    }
}
