import config from './config';
import { getName } from './formatter';
import OAuth2Client from './oauth2-client';
import Preferences from './preferences';

class Session {
    constructor() {
        // Remove redundant item from localStorage
        /** @todo Remove after 2024/12/31 */
        localStorage.removeItem('Session.data');

        this.initialize();
    }

    initialize() {
        console.debug('Session#initialize');

        this.data = {
            initialized: false,
            staff_id: undefined,
            division_id: undefined,
            company_id: undefined,
            email: '',
            username: '',
            last_name: '',
            first_name: '',
            dashboard: '',
        };

        this.scopes = [];
    }

    async fetchInfo() {
        console.debug('Session#fetchInfo');

        // Fetch session info from API
        const data = await OAuth2Client.fetchJSON(config.api.url + 'session_info', { credentials: 'include' });

        // Set user info
        this.data.staff_id = data.results.staff_id || data.results.user_id;
        this.data.division_id = data.results.division_id;
        this.data.company_id = data.results.company_id;
        this.data.email = data.results.email;
        this.data.first_name = data.results.first_name;
        this.data.last_name = data.results.last_name;

        // Set scopes
        this.scopes = data.scopes || data.results.scopes || [];

        // Set preferences
        const preferences = data.results.preferences || {};

        // Set dashboard from preferences
        /** @todo Move dashboard from "preferences" to separate column (server side), since it's not really a preference and won't be controllable by the user */
        this.data.dashboard = preferences.dashboard;
        delete preferences.dashboard;

        // Apply preferences loaded from session
        _.extend(Preferences, preferences);

        // Load preferences from localStorage
        const storedPreferences = JSON.parse(localStorage.getItem('preferences')) || {};

        // Apply preferences loaded from localStorage
        _.extend(Preferences, storedPreferences);

        this.data.initialized = true;
    }

    start() {
        console.debug('Session#start');

        // Show navbar
        $('nav.navbar').removeClass('d-none');

        this.#applySettings();
    }

    #applySettings() {
        console.debug('Session#applySettings');

        $('.navbar [data-outlet="account-name"]').text(getName(this.data));

        // Hide items for which user does not have permission to view
        $('.navbar [data-permission]').each((i, obj) => {
            const permissions = obj.dataset.permission.split(',');

            // If any of the permissions are allowed
            if (this.isAllowed(permissions)) {
                // Show the item
                obj.style.display = '';
            } else {
                // Hide the item
                obj.style.display = 'none';
            }
        });
    }

    end() {
        console.debug('Session#end');

        this.initialize();

        OAuth2Client.clearToken();

        console.log('Session.end(): session terminated');
    }

    /**
     * Checks whether session is initialized or not
     */
    isInitialized() {
        return this.data.initialized;
    }

    isAllowed(permission) {
        if (permission instanceof Array) {
            // Check if any of the items in the permission array are in the scopes array
            return _.some(permission, (p) => this.scopes.includes(p));
        } else {
            // Check if permission is in the scopes array
            return this.scopes.includes(permission);
        }
    }
}

export default new Session();