import OAuth2Client from '@/js/libs/oauth2-client';
import config from '@/js/app/config';
import logout from '@/js/app/authentication/views/logout';
import Session from '@/js/app/session';

export default new OAuth2Client({
    url: config.auth.url,
    client_id: config.auth.client_id,
    client_secret: config.auth.client_secret,
    token_name: 'hj-access-token',
    onAuthenticate: async () => {
        await Session.fetchInfo();
        Session.start();
    },
    onRefreshToken: () => {
        Session.fetchInfo();
        Session.start();
    },
    onRefreshTokenFail: logout
});
